<template>
    <div class="paymentDetailsReport">
        <!--<div class="info-box">-->
            <div class="filter-box">
                <div class="filter-bnts ">
                    <div class="bnt verticalCenter" :class="{selected:queryType==false}" @click="nowadays()" >
                        <div class="title">
                            <span>今天 <br/>({{userInfo.Rpt_Date}})</span>
                        </div>
                    </div>
                    <el-dropdown class="bnt verticalCenter" split-button  @click="queryType=true" :class="{selected:queryType==true}"  v-if="ReportDataFromDays>=0">
                        {{({0:"按日期范围",1:"按付款时间"})[showType]}}
                        <template #dropdown>
                            <el-dropdown-menu @click="queryType=true">
                                <el-dropdown-item @click="showType=0">按日期范围</el-dropdown-item>
                                <el-dropdown-item @click="showType=1">按付款时间</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <div class="inline-block" v-if="ReportDataFromDays>=0">
                        <el-date-picker class="from-date"  v-if="showType==0"
                            v-model="businessHours"
                            type="daterange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="defaultTime"
                            @change="change"
                            :disabled='queryType==false'
                            :disabled-date="disabledDate"
                        > </el-date-picker>
                        <el-date-picker class="from-date timerange" v-else
                            v-model="businessHours"
                            type="datetimerange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="defaultTime"
                            @change="change"
                            :disabled='queryType==false'
                            :disabled-date="disabledDate"
                            format="YYYY-MM-DD HH:mm"
                        > </el-date-picker>
                    </div>
                    <div class="inline-block">
                        <div class="lable-txt">付款方式：</div>
                    </div>
                    <div class="inline-block select">
                        <el-select v-model="value" multiple collapse-tags  placeholder="请选择" @removeTag="()=>value=[]">
                            <el-option
                            v-for="item in payType"
                            :key="item.PAY_ID"
                            :label="item.PAY_NAME"
                            :value="item.PAY_ID">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="inline-block select">
                        <el-select v-model="searchType" placeholder="按台号">
                            <el-option :value="1" label="按台号"></el-option>
                            <el-option :value="2" label="按结账单"></el-option>
                            <el-option :value="3" label="按结账金额"></el-option>
                        </el-select>
                    </div>
                    <div class="inline-block">
                        <div class="search-flex">
                            <div class="search-input"><input type="text" v-model="selectContent"  placeholder="模糊检索" /></div>
                            <div class="bnt-search" @click="GetBillCheck()" >查询</div>
                        </div>
                    </div>
                </div>
                <div class="right-bnts">
                    <div class="bnt"  @click="exportExcel()" ><i class="iconfont icon-daochu"></i>导出报表</div>
                </div>
            </div>
            <div class="content-box clearfix">
                <div class="table-box grey-table"  >
                    <el-table-vir class="el-table--scrollable-y" ref="tableEl" id="paytableEl" :data="tableFilter"  style="width: 100%;height:100%;" border show-summary 
                        :summary-method="getSummaries"  
                        @sort-change="sortChange" 
                        @current-change="handleCurrentChange"
                        :row-class-name="tableRowClass"
                        @cell-dblclick="orderDetail"
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                        <el-table-column prop="Rpt_Date" label="营业日期" min-width="80">
                            <template #default="scope">
                                <span>{{ new Date(scope.row.Rpt_Date).Format("yyyy-MM-dd")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="OrderNumber" label="账单数" min-width="50"></el-table-column>
                        <el-table-column sortable="custom" prop="Eat_CheckNo" label="结账单号" min-width="160">
                             <template #default="scope">
                                <span class="lefts link" >{{scope.row.Eat_CheckNo}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable="custom" prop="Eat_DeskName" label="台号" min-width="70">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_DeskName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable="custom" prop="POSName" label="收银POS" min-width="100">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.POSName}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable="custom" prop="Eat_Czy" label="收银员" min-width="80">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.Eat_Czy}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable="custom" prop="Eat_CheckTime" label="结账时间" min-width="150">
                            <template #default="scope">
                                <span  class="lefts">{{ new Date(scope.row.Eat_CheckTime).Format("yyyy-MM-dd hh:mm:ss")}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable="custom" prop="PayName" label="付款方式" min-width="100">
                            <template #default="scope">
                                <span class="lefts" >{{scope.row.PayName}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable="custom" prop="Payment_Money" label="结账金额" min-width="80" :formatter="priceFormat" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.Payment_Money!=0" >{{scope.row.Payment_Money}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable="custom" prop="Payment_FactMoney" label="实收金额" min-width="80" :formatter="priceFormat" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.Payment_FactMoney!=0" >{{scope.row.Payment_FactMoney}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column sortable="custom" prop="Payment_xMoney" label="虚收金额" min-width="80" :formatter="priceFormat" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.Payment_xMoney!=0" >{{scope.row.Payment_xMoney}}</span>
                            </template>
                        </el-table-column> 
                        <el-table-column prop="Remark" label="备注" min-width="80"></el-table-column> 
                        <el-table-column prop="Payment_HostTrace" label="交易流水号" min-width="80"></el-table-column> 
                    </el-table-vir>
                </div>
            </div>
        <tabs class="bottom-record-box">
            <div class="nav-box">
                <tab-nav class="nav-li">付款合计</tab-nav>
            </div>
            <tab-pane v-slot="{ isShow }">
                <div class="table-box grey-table"  v-table-el-height="'tableEl2'" v-if="isShow">
                    <el-table ref="tableEl2" id="paytotalTable" border :data="PayDetailTotalList"  style="width: 100%;" 
                    @current-change="handleCurrentChange"
                    :row-class-name="tableRowClass"
                    v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                        <el-table-column prop="PayName" label="支付方式" min-width="150"></el-table-column>
                        <el-table-column prop="Payment_Money" label="结账金额" min-width="150" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.Payment_Money!=0" >{{scope.row.Payment_Money}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Payment_FactMoney" label="实收金额" min-width="150" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.Payment_FactMoney!=0" >{{scope.row.Payment_FactMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Payment_xMoney" label="虚收金额" min-width="150" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.Payment_xMoney!=0" >{{scope.row.Payment_xMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="OrderCount" label="笔数" min-width="150"></el-table-column>
                    </el-table>
                </div>
            </tab-pane>
        </tabs>
        <!--权限控制-->
        <modal-load :isShow="checkShow">
            <check-user-button-model :isShow="checkShow" v-on:closeModel="checkShow=false" :checkBtnlist="checkBtnlist" @checkbtnReturn="checkbtnReturn" ></check-user-button-model>
        </modal-load>
     </div>
</template>

<script>
// import orderChannelModel from '../../../diningTable/model/orderChannelModel/orderChannelModel.vue'
import { newGuid } from '../../../../../common'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import checkUserButtonModel from '../../../checkUserRightModel/checkUserButtonModel.vue'

/**单据稽核报表 */
export default {
    components:{checkUserButtonModel},
    name:'paymentDetailsReport',
    computed:{
        tableFilter(){
            let list=Object.assign([],this.tableList);
            //排序
            if(this.sortable){
                let sorttype=this.sortable.order=='ascending'?'asc':'desc';
                list.order(this.sortable.prop,sorttype);
            }
            return list;
        },
        //限制门店用户查看几天以内的报表, -1:不能查看历史数据，0:不限制，大于0：限制查看几天内的报表
        ReportDataFromDays(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserViewReportDataFromDays||0
        },
        //合计
        total(){
            let total={
                Payment_Money:0,
                Payment_FactMoney:0,
                Payment_xMoney:0,
            };
            (this.tableFilter||[]).forEach(it=>{
                total.Payment_Money+=it.Payment_Money;
                total.Payment_FactMoney+=it.Payment_FactMoney;
                total.Payment_xMoney+=it.Payment_xMoney;
            })
            return total;
        },
    },
    data(){
        return {
            //0按日期范围 1按点单时间  2按点单区间
            showType:0,
            userInfo:'',
            /**表格数据 */ 
            tableList:[],
            /**排序 */
            sortable:null,
            /**总计报表 */
            PayDetailTotalList:[],
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            /**true: 日期范围    false：当前营业数据*/
            queryType:false,
            payType:[], /**支付方式 */
            value:[], /**选择的支付方式 */
            /**检索类型 */
            searchType:1,
            selectContent:'', /**搜索查询条件 */
            labelType:0,
            checkShow:false,
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
            checkBtnlist:{}
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        this.tempUserId='',this.tempOperatorName=''
        this.nowadays();
        
        this.labelType=0
        /** 获取支付方式 */
        this.$cacheData.AllPayTypes().then((data)=>{
            data.map(item=>{
                item.PayTypes.map(pay=>{
                    this.payType.push(pay)
                })
            })
        }).catch(e=>{
            this.$alert("支付方式获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
    },
    methods:{
        disabledDate(d){
            if(this.ReportDataFromDays>0 && this.userInfo.Rpt_Date){
                const oneDay =  24 * 3600 * 1000;
                const day = this.ReportDataFromDays * oneDay;
                let maxDate=new Date(this.userInfo.Rpt_Date).getTime()-oneDay;
                let minDate=maxDate-day;
                if(minDate>=d.getTime() || maxDate<d.getTime()){
                    return true;
                }
            }
            return false;
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            try {
                let ex1Data=this.$excelCommon.addElTableToAll(this.$refs.tableEl,this.tableList)
                let ws1=this.$excelCommon.addSheetCell(ex1Data);

                let ex2Data=this.$excelCommon.addElTableToAll(this.$refs.tableEl2,this.PayDetailTotalList)
                let ws2=this.$excelCommon.addSheetCell(ex2Data);

                var workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook,ws1,"付款明细表");
                XLSX.utils.book_append_sheet(workbook, ws2, "付款合计");
                var wbOut = XLSX.write(workbook, {
                    bookType: "xlsx",
                    bookSST: true,
                    type: "array"
                });
                FileSaver.saveAs(new Blob([wbOut], { type: "application/octet-stream" }),"付款明细表.xlsx");
            }catch(e){
                console.log(e, wbOut);
            }
        },
        /**今日 */
        nowadays(){
            this.queryType=false
            let Rpt_Date=new Date(this.userInfo.Rpt_Date);
            Rpt_Date.setDate(Rpt_Date.getDate()-1);
            this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];
        },
        change(){
            if(this.queryType==false){
                this.queryType=true
            }
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /**查询 */
        GetBillCheck(){
            this.PayDetailTotalList=[]
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let Begin_Date="",End_Date="";
            if(this.showType!=0){
                Begin_Date=new Date(time[0]).Format("yyyy-MM-dd hh:mm:00");
                End_Date=new Date(time[1]).Format("yyyy-MM-dd hh:mm:59");
            }else{
                Begin_Date=new Date(time[0]).Format("yyyy-MM-dd");
                End_Date=new Date(time[1]).Format("yyyy-MM-dd");
            }
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                DateType:this.showType,//日期格式  0:按日期; 1:按点单时间; 2:按点单区间
                DateRange_YN:this.queryType,//是否日期范围查询  true: 日期范围    false：当前营业数据
                Begin_Date:Begin_Date,//开始日期
                End_Date:End_Date, //结束日期
                Pay_IDList:this.value,//渠道id
                SearchKind:this.searchType,//检索类型
                SearchStr:this.selectContent, //搜索字段
                IsHideMarkedBills:this.$global.isLableData //是否隐藏带标签的订单
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            if(this.tempUserId!='' && this.tempOperatorName!=''){
                param.User_ID = this.tempUserId
                param.Operator_Name = this.Operator_Name
            }
            this.$httpAES.post("Bestech.CloudPos.PayReport",param,false).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList = d.ResponseBody.payReports
                    if(d.ResponseBody.PayDetailTotal){
                        d.ResponseBody.PayDetailTotal.forEach(item=>{
                            item.OrderCount = item.OrderCount +'笔'
                            this.PayDetailTotalList.push(item)
                        })
                    }
                }else if(d.ResponseHeader.ResultCode==3003){
                    /**权限不足,去验证保存权限 */
                    this.checkBtnlist = Object.assign(this.checkBtnlist,{ProjectItem_Key:d.ResponseHeader.Msg_Reserve})
                    this.checkShow = true;
                }else{
                    this.tableList=[]
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[]
                loading.close();
                this.$message.error(e);
                console.log(e);
            })
        },
        /**权限验证返回 */
        checkbtnReturn(date){
            this.checkShow = false;
            this.tempUserId = date.User_ID
            this.tempOperatorName = date.Login_Name
            this.GetBillCheck()
        },
        getSummaries({ columns }) {
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) { // 只找第一列放合计
                    sums[index] = '小计：'
                    return
                }else if (this.total[column.property]!=undefined) {
                    sums[index]=this.priceFormat(null,null,this.total[column.property]);
                } else {
                    sums[index] = '--'
                }
            })
            return sums
        },
        //奇偶行背景色不同
        setCurrent(row) {
            this.$refs.tableEl.setCurrentRow(row);
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        },
        tableRowClass({row, rowIndex}) {
            if ((rowIndex + 1) % 2 === 0) {
                return 'success-row';
            }
            return '';
        },
        /**数据排序 */
        sortChange(column){
            this.sortable=column;
        },
        //穿透到 明细报表
        orderDetail(row,column){
            if(column.property=="Eat_CheckNo"){//穿透 
                this.$emit("addFrame",{
                    key:row.Eat_CheckNo,
                    data:{checkNo:row.Eat_CheckNo,Rpt_Date:row.Rpt_Date,Is_RedBill:row.IsRed},
                    title:'结帐清单',
                    url:"billcheckdetailreport"
                });
            }
        },
    }
}
</script>

<style lang="scss" >
@import './paymentDetailsReport.scss'
</style>